import React, { Fragment } from 'react';

import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faCar from '@fortawesome/fontawesome-free-solid/faCar';

function FreePlacesRowAlt({ free, total, t }) {
  function isFreebroken() {
    return free === -1;
  }

  function placesWithoutFree() {
    return (
        <Fragment>
            <div className="reservation-row">
                <div className="reservation-icon">
                    <FontAwesomeIcon icon={faCar}/>
                </div>
                <div className="reservation-title">
                    <span>{`${t('reservation:parking:places:totalPlaces')}`}</span>
                </div>
            </div>
            <div className="reservation-description">
                {total}
            </div>
        </Fragment>
  );
  }

  function placesWithFree() {
        return (
            <div className="reservation-title">
        <span>
          {`
						${free}
						${t('reservation:parking:places:from')}
						${total} ${t('reservation:parking:places:placesFree')}
					`}
        </span>
      </div>
    );
  }

  function places() {
    if (isFreebroken()) {
      return placesWithoutFree();
    }

    return placesWithFree();
  }

  return (
    <Fragment>
      {places()}
    </Fragment>
  );
}

export default FreePlacesRowAlt;
