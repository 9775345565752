import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { observer } from "mobx-react";
import { observable } from "mobx";
import ButtonSelect from "./ButtonSelect";

import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import faCheck from "@fortawesome/fontawesome-free-solid/faCheck";

import mapStore from "../../Stores/MapStore";
import "./style.css";
import { translate } from "react-i18next";

@translate(["filter"], { wait: true })
@observer
class Filter extends Component {
  @observable mapStore;
  componentDidMount() {
    this.props.UIStore.computeMapStyle();
  }

  componentWillUnmount() {
    this.props.UIStore.computeMapStyle();
  }

  render() {
    const { filterVisibility } = this.props.UIStore;
    return filterVisibility ? (
      <div className="filter__inner">
        {this.renderHead()}
        {this.renderBlocks()}
      </div>
    ) : null;
  }

  renderHead() {
    const { statistics = [], t } = this.props;

    const info = statistics.map((field, index) => (
      <div key={index}>
        <div className="filter-digits">{field.number}</div>
        <div className="filter-digits-title">{field.title}</div>
      </div>
    ));

    return (
      <Fragment>
        <div className="filter-zone">{t("filter:filter")}</div>
        <div className="filter-info inline-flex">{info}</div>
      </Fragment>
    );
  }

  click = (typeIndex, categoryIndex) => {
    let indexOfCategoryIndex = mapStore.activeCategories[typeIndex].indexOf(
      categoryIndex
    );
    if (indexOfCategoryIndex === -1) {
      mapStore.activeCategories[typeIndex].push(categoryIndex);
    } else {
      mapStore.activeCategories[typeIndex].splice(indexOfCategoryIndex, 1);
    }
    mapStore.filteredData = mapStore.filterData(mapStore.data);
  };

  renderBlockFields(fields, title, typeIndex) {
    return fields.map((field, index) => {
      let check = false;
      mapStore.activeCategories[typeIndex].forEach(categoryIndex => {
        if (categoryIndex === index) {
          check = true;
          return null;
        }
      });
      return (
        <div key={index}>
          <span>
            <ButtonSelect
              bg={title === "Прочее" ? "btn-options-purple" : "btn-options"}
              typeIndex={typeIndex}
              categoryIndex={index}
              checked={check}
              change={this.click}
            >
              {field.filterName}
            </ButtonSelect>
          </span>
        </div>
      );
    });
  }

  renderBlocks = () => {
    const { types = [], categories = {} } = this.props;

    const blocks = types.map((type, index) => (
      <Fragment key={index}>
        <div className="filter-block">
          <span className="filter-title-main">{type}</span>
          <span
            className={`filter-title-main-icon
                        ${type === "Прочее" ? "color-purple" : "color-grey"}`}
          >
            <FontAwesomeIcon icon={faCheck} />
          </span>
        </div>
        <div className="filter-options-line  inline-flex flex-wrap">
          {this.renderBlockFields(categories[index], type, index)}
        </div>
      </Fragment>
    ));

    return blocks;
  };
}

Filter.propTypes = {
  types: PropTypes.arrayOf(PropTypes.string),
  statistics: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      number: PropTypes.number
    })
  )
};

export default Filter;
